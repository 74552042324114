import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from '../images/logo2.png'
//import burger from '../js/burger.js'





const Header = ({ siteTitle }) => (
  <header
    style={{
      borderBottom: '1px solid rgba(0,0,0,.05)',
    }}
  >
    <nav>
          <div><Link to="/"><img className="nav-logo" height="30" src={logo} alt="Cynesige logo"></img></Link></div>
          <ul className="nav-links" style={{listStyle: 'none'
        }}>
            <li><Link to="/">Home</Link> </li>
            <li><Link to="/about">About</Link> </li>
            <li><Link to="/services">Services</Link> </li>
            <li><Link to="/pricing">Pricing</Link> </li>
            <li><Link to="/contact">Contact</Link> </li>
          </ul>
          <div className="burger">
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
    </nav>
    
  </header>
  
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}


export default Header
 