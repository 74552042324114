/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "./layout.css"

import logo from '../images/logo2.png'
import facebook from '../images/facebook.png'
import instagram from '../images/instagram.png'
import twitter from '../images/twitter.png'

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: `0 auto`,
            //maxWidth: '70rem',
            //padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
            minHeight: '100vh'
          }}
        >
          <main>{children}</main>
          </div>
          <footer>
            <div className="footer_info">
              <div className="footer_grid">
                <div className="footer_grid_left">
                <img style={{filter: 'invert(1)', width: 200}} src={logo} alt="cynesige logo"></img>
                <p>Building modern and responsive websites for companies and individuals that stand out.</p>
                <div className="footerSocial">
                  <a href="https://www.instagram.com/cynesigewebdev/" target="blank_"><img src={instagram} alt="instagram"></img></a>
                  <a href="https://www.facebook.com/cynesige/" target="blank_"><img src={facebook} alt="facebook"></img></a>
                  <a href="https://twitter.com/cynesigewebdev" target="blank_"><img src={twitter} alt="twitter"></img></a>
                </div>
                </div>
                <div className="footer_grid_right">
                <ul>
                  <li><Link to="/about">About</Link> </li>
                  <li><Link to="/services">Services</Link> </li>
                  <li><Link to="/pricing">Pricing</Link> </li>
                  <li><Link to="/contact">Contact</Link> </li>
                </ul>
                </div>
              </div>
            </div>
            © {new Date().getFullYear()}, Cynesige Web Development
          </footer>
        
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
