import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PricingPage = () => (
  <Layout>
    <SEO title="Pricing" />
    <div className="container">
    <h1 className="PageH1">Pricing</h1>
    <p>Our goal is to deliver the best possible services at a competitive price. Setting a general price for a website is challenging and we believe every company deserves a professional webpage. Therefore, we set our prices specifically for every project. Send us a message where you describe your preferences and budget and we will get back to you with a solution.</p>
    <div className="priceGrid">
        <div className="priceTable">
            <h3>Starter</h3>
            <p className="priceTag">€120</p>
            <p>Great for small sites up to five pages built with just html, CSS and JavaScript</p>
            <ul>
                <li>Up to five pages</li>
                <li>HTML, CSS, JavaScript</li>
                <li>1 month full support</li>
            </ul>
        </div>
        <div className="priceTable">
            <h3>Pro</h3>
            <p className="priceTag">€500</p>
            <p>Ideal for medium sized websites with more flexibility</p>
            <ul>
                <li>All <p id="previousPlan">Starter</p> features</li>
                <li>Unlimited pages</li>
                <li>Wordpress</li>
                <li>Webpage uploaded to your host</li>
                <li>1 month full support</li>
            </ul>
        </div>
        <div className="priceTable">
            <h3>Custom</h3>
            <p>Contact sales for price</p>
            <p>Perfect for industry leaders. Our most flexible option</p>
            <ul>
                <li>All <p id="previousPlan">Pro</p> features</li>
                <li>Unlimited pages</li>
                <li>CMS of your choice, such as Wordpress and Contentful</li>
                <li>Analytics integration</li>
                <li>1+ month full support according to your needs</li>
                <li>Additional features of your choice</li>
            </ul>
        </div>
    </div>
    
    </div>
  </Layout>
)

export default PricingPage
 